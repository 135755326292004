import React, { useState } from "react";
import "./style.css";

import PickerComp from "./Picker";
import { useT } from "../../utils/locales/useLanguageState";

const SlotMachine: React.FC = () => {
  const t = useT();

  const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];

  const [reload, setReload] = useState(false);
  
  return (
    <div style={{
      justifyContent: 'center',
      alignContent: "center",
      textAlign: "center"
    }}>
      <PickerComp
        items={items}
        onIndexChange={() => setReload(!reload)}
      />
      <p style={{
        fontSize: '0.8em',
        opacity: 0.5,
      }}>
        {t("GiveItAWhirl")}<br />
        {t("DonTForget")} <br />
        {t("AnotherSpin")}
      </p>
    </div>
  );
};

export default SlotMachine;

