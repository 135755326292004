import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Daily, Friends, Earn, Name, Task } from "../../components/earn";

// Assets
import logo from "../../assets/images/icon.svg";
import ShopIcon from "../../assets/icons/shop-icon.png";
import FrensIcon from "../../assets/icons/frens-icon.png";
import TaskIcon from "../../assets/icons/task-icon.png";
import AirdropIcon from "../../assets/icons/airdrop-icon.png";
import EarnIcon from "../../assets/icons/earn-icon.png"

import { setMenu } from "../../slices/selected-menu/slice";

// Components
import AppNavbarComp from "../../components/navbars/App.navbar.comp";


// Pages
import Airdrop from "../airdrop/airdrop.page";
import StorePage from "../store";
import BasketProductsPaymentPage from "../basket/basketProductsPaymentPage";

// Types
import { IProductModel } from "../../types/models/redux/productModel";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/index";
import { setCurrentShopView } from "../../slices/shopViewer/slice";
import { updateProductBasket } from "../../slices/shopBasket/slice";

//Utils
import { motion, AnimatePresence } from "framer-motion";
import { useT } from "../../utils/locales/useLanguageState";
import ProductPaymentPage from "../store/ProductPaymentPage";
import ProductInvoicePage from "../store/ProductInvoicePage";


export const Main: React.FC = () => {
  const t = useT();
  const dispatch = useDispatch<AppDispatch>();

  const [selectedComponent, setSelectedComponent] = useState("earn");
  
  const selectedMenu = useSelector((state: any) => state.selectedMenu.menu);

  const [isMobile, setIsMobile] = useState(false);

  const handleIconClick = (icon: string) => {
    dispatch(setMenu(icon));
    setSelectedComponent(icon);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const savedBasketData = localStorage.getItem('productBasket'); // Adjusted to 'productBasket'
    if (savedBasketData) {
      const parsedData = JSON.parse(savedBasketData);
      // Ensure data is in expected format before dispatching
      const validData = parsedData.map((product: IProductModel) => ({
        ...product,
        productQuantity: product.productQuantity || 1,
        //@ts-ignore
        retail_price: parseFloat(product.retail_price)
      }));
      dispatch(updateProductBasket(validData)); // Dispatch the action to update the basket state
    }
  }, [dispatch]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderSelectedComponent = () => {
    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={selectedComponent} // Her bileşen için benzersiz bir key
          initial={{ opacity: 0, x: -100 }} // İlk durumda opacity 0 ve sağdan gelsin
          animate={{ opacity: 1, x: 0 }}   // Görünür duruma gelsin ve x ekseninde 0 noktasına gelsin
          exit={{ opacity: 0, x: 100 }}   // Çıkarken opacity 0 olsun ve sola doğru gitsin
          transition={{ duration: 0.3 }}   // Animasyon süresi
        >
          {(() => {
            switch (selectedComponent) {
              case "earn":
                return <Earn />;
              case "daily":
                return <Daily />;
              case "frens":
                return <Friends />;
              case "name":
                return <Name />;
              case "task":
                return <Task />;
              case "airdrop":
                return <Airdrop />;
              case "store":
                return <StorePage />;
              case "basketProductsPayment":
                return <BasketProductsPaymentPage onPayNow={() => handleIconClick("earn")} />;
              case "productInvoice":
                return <ProductInvoicePage />;
              default:
                return <Earn />;
            }
          })()}
        </motion.div>
      </AnimatePresence>
    );
  };


  useEffect(() => {
    setSelectedComponent(selectedMenu);
  }, [selectedMenu]);

  return (
    <section className="section header" style={{
      display: "flex",
      flexDirection: "column",
    }}>
      {
        isMobile ? (
          <>
            <AppNavbarComp handleNavigate={(path) => handleIconClick(path)} />
            <Container fluid className="container-custom">

              {renderSelectedComponent()}
            </Container>

            <div className="d-flex align-items-center justify-content-center pt-5">
              <div className="d-flex justify-content-around align-items-center navbar-mobile">
                <div className=" text-center">
                  <span
                    className={`navbar-items-mobile ${selectedMenu === "earn" ? "selected-icon" : ""
                      }`}
                    onClick={() => handleIconClick("earn")}
                  >
                    <img src={EarnIcon} alt="" className="menu-logo" />
                    {t("Earn")}
                  </span>
                </div>
                <div className=" text-center">
                  <span
                    className={`navbar-items-mobile ${selectedMenu === "frens" ? "selected-icon" : ""
                      }`}
                    onClick={() => handleIconClick("frens")}
                  >
                    <img src={FrensIcon} alt="" className="menu-logo" />
                    {t("Frens")}
                  </span>
                </div>

                <div className="text-center" style={{ position: "relative" }}>
                  <span
                    className={`navbar-items-mobile`}
                    style={{
                      backgroundColor: `${selectedMenu === "store" ? "#5271ff" : "transparent"}`,
                      borderRadius: "16px",

                    }}
                    onClick={() => {
                      dispatch(setCurrentShopView("store"));
                      handleIconClick("store")
                    }}
                  >
                    {/* <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "100px", // Verilen genişlik
                        height: "90px", // Verilen yükseklik
                        backgroundColor: `${selectedMenu === "store" ? "#5271ff" : "rgb(9, 67, 140)" }`, // Arka plan rengi
                        borderRadius: "40%", // Yuvarlak şekil oluşturmak için
                        transform: "scale(0.755238) translate(-50%, -50%)", // Ölçeklendirme ve ortalama
                        transformOrigin: "0 0", // Transform origin ayarı
                        clipPath: "url(#__id2256)", // Belirtilen clip-path
                        touchAction: "pan-x pan-y pinch-zoom", // Dokunma eylemleri
                        zIndex: -1, // İkonun arkasında kalması için
                        borderTopRightRadius: "40%", // Sağ üst köşe yuvarlaklığı
                        borderTopLeftRadius: "40%", // Sol üst köşe yuvarlaklığı
                        borderBottomLeftRadius: "40%",
                        borderBottomRightRadius: "40%"
                      }}
                    ></div> */}
                    <img
                      src={ShopIcon}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                        position: "relative",
                        zIndex: 1
                      }}
                    />
                    {t("Wikz")}
                  </span>
                </div>

                <div className=" text-center">
                  <span
                    className={`navbar-items-mobile ${selectedMenu === "task" ? "selected-icon" : ""
                      }`}
                    onClick={() => handleIconClick("task")}
                  >
                    <img
                      src={TaskIcon}
                      alt=""
                      className="menu-logo"
                      style={{ marginLeft: "2px" }}
                    />
                    {t("Tasks")}
                  </span>
                </div>
                <div className=" text-center">
                  <span
                    className={`navbar-items-mobile ${selectedMenu === "airdrop" ? "selected-icon" : ""
                      }`}
                    onClick={() => handleIconClick("airdrop")}
                  >
                    <img
                      src={AirdropIcon}
                      alt=""
                      className="menu-logo"
                      style={{ marginLeft: "2px" }}
                    />
                    {t("Airdrops")}
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Container fluid className="container-custom pt-5">
            <AppNavbarComp handleNavigate={(path) => handleIconClick(path)} />
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img src={logo} alt="" className="logo me-2" />
                <p className="heading">235.15K</p>
              </div>
              <div className="d-flex align-items-center">
                <p className="heading">0x95...889</p>
                <i className="bi bi-caret-down-fill fs-24"></i>
              </div>
            </div>

            {renderSelectedComponent()}

            <div className="d-flex align-items-center justify-content-center py-5">
              <div className="d-flex justify-content-between align-items-center navbar">
                {/* Desktop Bottom Navbar */}
                <span
                  className={`navbar-items ${selectedMenu === "daily" ? "selected-icon" : ""
                    }`}
                  onClick={() => handleIconClick("daily")}
                >
                  <img src={EarnIcon} alt="" className="menu-logo" />
                  {t("Earn")}
                </span>
                <span
                  className={`navbar-items ${selectedMenu === "frens" ? "selected-icon" : ""
                    }`}
                  onClick={() => handleIconClick("frens")}
                >
                  <img src={FrensIcon} alt="" className="menu-logo" />
                  {t("Frens")}
                </span>

                <span
                  className={`navbar-items ${selectedMenu === "store" ? "selected-icon" : ""
                    }`}
                  onClick={() => {
                    dispatch(setCurrentShopView("store"));
                    handleIconClick("store")
                  }}
                >
                  <img src={ShopIcon} alt="" className="menu-logo" />
                  {t("Wikz")}
                </span>

                <span
                  className={`navbar-items ${selectedMenu === "task" ? "selected-icon" : ""
                    }`}
                  onClick={() => handleIconClick("task")}
                >
                  <img src={TaskIcon} alt="" className="menu-logo" />
                  t{t("Tasks")}
                </span>
                <span
                  className={`navbar-items ${selectedMenu === "airdrop" ? "selected-icon" : ""
                    }`}
                  onClick={() => handleIconClick("airdrop")}
                >
                  <img src={AirdropIcon} alt="" className="menu-logo" />
                  {t("Airdrops")}
                </span>
              </div>
            </div>
          </Container>
        )}
    </section>
  );
};
