import React, { useState, useRef, useEffect } from 'react';
import './styles.css'; // Ensure this path is correct
import wikzLogo from '../../assets/logos/wikz-logo.svg'
import BasketIcon from "../../assets/icons/basket-icon.png"
import MenuIcon from "../../assets/icons/menu-icon.png"
import BasketDrawerComp from '../drawers/BasketDrawerComp';
import { useAppSelector } from '../../store';


import { useT, useSetLanguage, useLanguage } from '../../utils/locales/useLanguageState';

interface IAppNavbar {
  handleNavigate: (path: string) => void;
}

const AppNavbarComp = (props: IAppNavbar) => {

  const t = useT();

  const basket = useAppSelector((state) => state.basket);


  const [language, setLanguage] = useState<'en' | 'tr'>(() => {
    const savedLanguage = localStorage.getItem('language') as 'en' | 'tr';
    return savedLanguage || 'en';
  });

  const setLang = useSetLanguage();
  const useLang = useLanguage();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [vibrate, setVibrate] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const [isBasketMenuOpen, setIsBasketMenuOpen] = useState(false);
  const cartMenuRef = useRef<HTMLDivElement | null>(null);

  const toggleBasketMenu = () => {
    setIsBasketMenuOpen(!isBasketMenuOpen);
  };

  const closeBasketMenu = () => {
    setIsBasketMenuOpen(false);
  };


  const handleLanguageMenu = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = event.target.value as 'en' | 'tr';
    setLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage); // Save to localStorage
    setLang(selectedLanguage);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  // Handle clicks outside the menu to close it
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (cartMenuRef.current && !cartMenuRef.current.contains(event.target as Node)) {
        closeBasketMenu();
      }
    };

    if (isBasketMenuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isBasketMenuOpen]);

  // Set initial language from localStorage
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') as 'en' | 'tr';
    if (savedLanguage) {
      setLanguage(savedLanguage);
      setLang(savedLanguage); // Initialize the language state with the stored value
    } else {
      useLang === 'tr' ? setLanguage('tr') : setLanguage('en');
    }
  }, [setLang, useLang]);


  return (
    <nav className="app-navbar">
      <div className="navbar-container">
        <button className="hamburger-button" onClick={toggleMenu}>
          <img
            src={MenuIcon}
            alt=""
            style={{ width: "25px", height: "25px" }}
          />
        </button>
        <div className="appbar-logo" onClick={()=>props.handleNavigate('earn')}>
          <img
            src={wikzLogo}
            alt=""
            style={{ width: "150px", height: "40px" }}
          />
        </div>
        <div className="cart-icon" onClick={toggleBasketMenu}>
          <img
            src={BasketIcon}
            alt=""
            style={{ width: "25px", height: "25px" }}
          />
          {basket.productTotalQuantity > 0 && (
            <span
            style={{
              position: 'absolute',
              top: '0',
              right: '-10px',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              color: 'white',
              borderRadius: '50%', // Tam yuvarlak yapmak için
              padding: '4px', // Yuvarlak görünüm için padding değerini dengeliyoruz
              fontSize: '12px',
              fontWeight: '800',
              width: '20px', // Sabit genişlik
              height: '20px', // Sabit yükseklik
              display: 'flex',
              alignItems: 'center', // İçeriği dikeyde ortalamak için
              justifyContent: 'center', // İçeriği yatayda ortalamak için
            }}
          >
            {basket.productTotalQuantity}
          </span>
          
          )}
        </div>
      </div>
     {/* Sağdan çıkan Basket Menü */}
    <div ref={cartMenuRef} className={`basket-menu ${isBasketMenuOpen ? 'open' : ''}`}>
      <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
           <BasketDrawerComp
          isOpen={isBasketMenuOpen}
          closeDrawer={closeBasketMenu}
          onCheckout={() => props.handleNavigate('basketProductsPayment')}
        />
      </div>
    </div>


      {/* Soldan çıkan Menü */}

      <div ref={menuRef} className={`menu ${isOpen ? 'open' : ''}`}>
        <button className="close-button" onClick={closeMenu}>
          &times;
        </button>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <div className="appbar-logo" onClick={()=>props.handleNavigate('earn')}>
            <img
              src={wikzLogo}
              alt=""
              style={{ 
                width: "150px", 
                height: "40px",
                marginBottom: '20px'
              }}
            />
          </div>
          <div className="divider"></div>
          <div className="menu-item">
            <label>
              <span style={{
                fontWeight: '300',
              }}>{t('Vibrate')}</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={vibrate}
                  onChange={() => setVibrate(!vibrate)}
                />
                <span className="slider"></span>
              </label>
            </label>
          </div>
          <div className="divider"></div>
          <div className="menu-item">
            <label>
              <span style={{
                fontWeight: '300',
              }}>{t('Language')}</span>
              <select
                value={language}
                onChange={(e) => handleLanguageMenu(e)}
                className="custom-select"
                style={{
                  fontWeight: '300',
                }}
              >
                <option value="en">English</option>
                <option value="tr">Turkish</option>
              </select>
            </label>
          </div>
          <div className="divider"></div>
          <div className="menu-item">
            <button style={{
                fontWeight: '300',
              }} onClick={() => alert('Logout')}>{t("Dashboard")}</button>
          </div>
          <div className="divider"></div>
          <div style={{
                fontWeight: '300',
              }} className="menu-item">
            <button style={{
                fontWeight: '300',
              }} onClick={() => alert('Logout')}>{t("Orders")}</button>
          </div>
          <div className="divider"></div>
          <div style={{
                fontWeight: '300',
              }} className="menu-item">
            <button style={{
                fontWeight: '300',
              }} onClick={() => alert('Logout')}>{t("Support")}</button>
          </div>
          <div className="divider"></div>
          <div style={{
                fontWeight: '300',
              }} className="menu-item">
            <button style={{
                fontWeight: '300',
              }} onClick={() => alert('Logout')}>{t("TelegramChannel")}</button>
          </div>
        </div>
      </div>
      {(isOpen || isBasketMenuOpen) && (
  <div className="backdrop open" onClick={() => {
    closeMenu();
    closeBasketMenu();
  }} />
)}

    </nav>
  );
};

export default AppNavbarComp;
