import React, { useEffect, useState } from 'react';
import '../styles.css';

import { usdt } from '../../../slices/usdt/api';
import { not } from '../../../slices/not/api';
import { wikz } from '../../../slices/wikz/api';

//Redux
import { useDispatch } from "react-redux";
import { AppDispatch } from '../../../store';

import AssetListUsdtItemComp from './item/Asset.List.Usdt.Item.Comp';
import AssetListWikzItemComp from './item/Asset.List.Wikz.Item.Comp';
import AssetListNotItemComp from './item/Asset.List.Not.Item.Comp';
import AssetListKeyItemComp from './item/Asset.List.Key.Item.Comp';
import { useT } from '../../../utils/locales/useLanguageState';


const AssetsListComp: React.FC = () => {
  const t = useT();
  let promise: Promise<any>;
  const dispatch: AppDispatch = useDispatch();


  useEffect(() => {
    promise = Promise.all([
      dispatch(usdt.initiate({})),
      dispatch(not.initiate({})),
      dispatch(wikz.initiate({}))
    ])

    promise.then((res) => {
    });
  }, []);

  return (
    <div>
      <div
        className="center-text"
        style={{
          fontSize: "1.3rem",
          fontWeight: "1000",
          margin: "0.1rem 0 0.5rem 0"
        }}
      >{t("YourBalance")}</div>
      <div className="assets-list-box">
        <AssetListWikzItemComp />
        <AssetListNotItemComp />
        <AssetListUsdtItemComp />
        <AssetListKeyItemComp/>
      </div>
    </div>

  );
};

export default AssetsListComp;
