// SkeletonLoader.tsx
import React from 'react';
import { motion } from 'framer-motion'; // Import motion from framer-motion
import './styles.css'; // Import your styles

const SkeletonLoader = () => {
    return (
        <div className="col-6 col-md-4 col-lg-3 mb-3" style={{ padding: '0 5px' }}>
            <motion.div
                className="card"
                style={{
                    backgroundColor: '#313f54', // Same background color as StoreProductCard
                    borderRadius: '10px',
                    height: "180px", // Match height with StoreProductCard
                    marginBottom: "80px",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    border: 'none',
                    filter: 'blur(3px)' // Apply blur effect
                }}
                initial={{ opacity: 0.5 }} // Initial opacity for the fade-in effect
                animate={{ opacity: 1 }} // Animate to full opacity
                transition={{ duration: 1, ease: "easeInOut", repeat: Infinity, repeatType: "reverse" }} // Continuous fade-in/out
            >
                {/* Image Placeholder */}
                <div className="card-body text-center p-0" style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{
                        borderRadius: '10px',
                        width: '175px',
                        height: '175px',
                        //backgroundColor: 'rgba(255, 255, 255, 0.5)', // White transparent background
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)' // Optional shadow for better visibility
                    }} />
                </div>
                
                {/* Product Name and Price Placeholder */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    marginLeft: '10px',
                    marginTop: "10px",
                }}>
                    {/* Product Name */}
                    <div className="card-header" style={{
                        color: '#ffffff',
                        borderBottom: 'none',
                        padding: 0,
                        maxHeight: '2.5rem',
                        textAlign: 'left',
                        lineHeight: '18px',
                        height: '2.5rem',
                        //backgroundColor: 'rgba(255, 255, 255, 0.5)', // White transparent background for header
                        borderRadius: '5px', // Slight rounding for header
                        width: '70%', // Width of the skeleton name
                    }}>
                        <span style={{
                            fontSize: '0.8rem',
                            fontWeight: '400',
                            //backgroundColor: 'rgba(255, 255, 255, 0.5)', // Background for skeleton text
                            height: '1rem', // Fixed height
                            display: 'inline-block',
                            width: '100%', // Width of the skeleton text
                        }} />
                    </div>
                    
                    {/* Product Price */}
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                        <span style={{ color: '#ffffff', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontFamily: "Impact" }}>
                            <strong style={{ fontSize: "1.4rem", fontWeight: '700' }}>
                                <span style={{ marginRight: "0.3rem" }}>$</span>
                                <span style={{
                                    //backgroundColor: 'rgba(255, 255, 255, 0.5)', // Background for skeleton price
                                    height: '1rem', // Fixed height
                                    display: 'inline-block',
                                    width: '50%', // Width of the skeleton price
                                }} />
                            </strong>
                        </span>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}

export default SkeletonLoader;
