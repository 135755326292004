import React, { useState, useEffect } from "react";
import DaysRemoveBg from "../../assets/images/days-r-bg.png";
import Question from "../../assets/images/Question.png";
import GreenTick from "../../assets/images/greentick.png";
import CoinIcon from "../../assets/icons/coin-icon.png";
import { Image } from "react-bootstrap";
import SlideUpPanel from "../TaskSlider";

import CallendarIcon from "../../assets/icons/calendar-icon.png"


import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { dailySelector } from "../../slices/daily-login/slice";
import { loginClaim } from "../../slices/api";
import { dailyCardSelector } from "../../slices/daily-card/slice";
import formatNumber from "../../helpers/format-number";
import { useT } from "../../utils/locales/useLanguageState";
interface Task {
  id: string;
  heading: string;
  img: string;
  resetTime: string;
  completed: boolean;
  startTime?: number; // New property to store start time
  questions?: Question[];
}

interface Question {
  question: string;
  options: string[];
  correctAnswer: string;
}

const dailyData = [
  {
    id: "login",
    heading: "Daily Log-in",
    img: CallendarIcon,
    resetTime: "00:00",
    completed: true,
  }
];

const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
};

export const Daily = () => {
  const t = useT();
  const dispatch: AppDispatch = useDispatch();
  const { status: loginStatus, days } = useSelector(dailySelector);

  const states = {
    login: loginStatus,
  };

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [activeTask, setActiveTask] = useState<string | null>(null);
  const [tasks, setTasks] = useState<Task[]>(dailyData);

  useEffect(() => {
    const sessionStartTime = new Date().getTime();
    localStorage.setItem("sessionStartTime", sessionStartTime.toString());
  }, []);

  useEffect(() => {
    console.log("loginStatus", days);
  }, [days]);

  const getSessionStartTime = (): number | null => {
    const storedSessionStartTime = localStorage.getItem("sessionStartTime");
    if (storedSessionStartTime) {
      return parseInt(storedSessionStartTime, 10);
    }
    return null;
  };

  const handleClosePanel = (task: string | null = null) => {
    setIsPanelOpen(!isPanelOpen);
    setActiveTask(task);
  };

  const handleLoginClaim = async () => {
    dispatch(loginClaim.initiate({}));
  };


  useEffect(() => {
    const interval = setInterval(() => {
      const sessionStartTime = getSessionStartTime();
      if (!sessionStartTime) return;

      const now = new Date().getTime();

      const updatedTasks = tasks.map((task) => {
        const startTime = task.startTime || now; // Use current time if startTime is not set
        const taskElapsedTime = now - startTime;

        const resetTime = new Date(now);
        const [hours, minutes] = task.resetTime.split(":");
        resetTime.setUTCHours(Number(hours), Number(minutes), 0, 0);

        const resetTimeTimestamp = resetTime.getTime(); // Convert resetTime to timestamp

        if (
          !task.completed &&
          taskElapsedTime >= 0 &&
          now >= resetTimeTimestamp
        ) {
          return { ...task, completed: true };
        }

        return { ...task, startTime }; // Update startTime to maintain state
      });

      setTasks(updatedTasks);
    }, 1000);

    return () => clearInterval(interval);
  }, [tasks]);

  return (
    <section className="days-section">
      <p className="heading my-3">{t("DailyTasks")}</p>
      <div className="daily-task-cards">
        {tasks.map((card, index) => {
          const status = states["login"];
          return (
            <div
              key={index}
              onClick={() => handleClosePanel(card.id)}
              className={`daily-card ${status.canClaim ? "completed" : "not-completed"
                } ${isPanelOpen ? "panel-open" : ""}`}
            >
              <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "25px",
              }}>
                <img
                  src={card.img}
                  style={
                    card.img === DaysRemoveBg
                      ? { width: "9px", height: "90px", marginTop: "-14px" }
                      : {}
                  }
                  alt=""
                />
                <h6 style={{
                  alignItems: "center",
                  display: "flex",
                  fontWeight: "800",
                  margin: "0"
                }}>{t("DailyLogIn")}</h6>
              </div>

              <h6 style={{
                alignItems: "center",
                display: "flex",
                fontWeight: "800",
                fontSize: "0.7rem",
                margin: "0",
                backgroundColor: "#5271ff",
                padding: "0.4rem",
                borderRadius: "5px",
              }}>
                {status.canClaim ? t("Claim") :
                  formatTime(status.remainTimeForClaim)}
              </h6>

            </div>
          );
        })}
        <SlideUpPanel
          show={isPanelOpen}
          onClose={() => handleClosePanel(null)}
          activeTask={activeTask}
        >
          {activeTask === "login" && (
            <>
              <div className="d-flex align-items-center w-100">
                <h5
                  className="my-3 flex-grow-1 text-center"
                  style={{ marginTop: "25px" }}
                >
            
                </h5>
                <span
                  onClick={() => handleClosePanel(null)}
                  className="close cursor-pointer d-flex align-items-center justify-content-end"
                >
                  <i className="bi bi-x-circle" style={{
                    position: "relative",
                    top: "1rem",
                  }}></i>
                </span>
              </div>{" "}
              <p className="w-75 ">
                {t("CompleteYourDaily")}
              </p>
              <div className="days-container my-2">
                <div className="days">
                  {days.map((item: any, index: number) => (
                    <div
                      key={item.day}
                      className={`days-item ${item.day < loginStatus.daily.day ? "active" : ""
                        } ${item.day === loginStatus.daily.day ? "next-active" : ""
                        }`}
                    >
                      {item.day < loginStatus.daily.day ? (
                        <span className="dot" key={item.day}>
                          <img className="w-100" src={GreenTick} alt="" />
                        </span>
                      ) : undefined}
                      <div>
                        <p className="days-title">{t("Day")} {item.day}</p>
                      </div>
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.3rem",
                      }}>
                      <Image
                        src={CoinIcon}
                        alt={`Day ${item.title} reward`}
                        className="days-logo"
                      />
                      <h4 className="heading" style={{fontWeight:"800", margin:0, lineHeight:1.5}}>{formatNumber(item.reward)}</h4>
                      </div>
                     
                    </div>
                  ))}
                  <div style={{
  
                  }}>
                    <button
                    style={{
                      backgroundColor:"#5271ff",
                      color:"white",
                      borderRadius:"5px",
                      border:"none",
                      cursor:"pointer",
                      justifyContent:"center",
                      alignItems:"center",
                      textAlign:"center",
                      width:"150px",
                      height:"40px",
                      margin:"1rem auto",
                    }}
                    onClick={handleLoginClaim}
                    >
                    <h6 style={{
                      alignItems: "center",
                      display: "flex",
                      fontWeight: "800",
                      textAlign:"center",
                      justifyContent:"center",
                      margin: "0.3rem",
                    }}>
                      {loginStatus.canClaim
                        ? "Claim"
                        : formatTime(loginStatus.remainTimeForClaim)}
                    </h6>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </SlideUpPanel>
      </div>
    </section>
  );
};
