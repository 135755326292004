import LanguageKeysTR from "./tr";

export class LanguageKeysEN extends LanguageKeysTR {
    SpinTheWheel="Spin The Wheel";
    PleaseWait="Please Wait";
    Hours="Hours";
    Earn="Earn";
    Frens="Frens";
    Wikz="Wikz";
    Tasks="Tasks";
    Airdrops="Airdrop";
    GiveItAWhirl="Give it a whirl!";
    DonTForget="Don't forget, you can come back in 8 hours";
    AnotherSpin="for another spin.";
    InviteFriends="INVITE FRENS";
    InviteAFriend="Invite a friend";
    GiveAwayWithoutLottery="Give away without lottery!";
    ReferralPrizePool="Referral Prize Pool";
    ForYouAndYourFriend=" for you and your friend";
    ListOYourFriends="List of your friends";
    Copied="Copied!";
    //
    DailyTasks="DAILY TASKS";
    Claim="Claim";
    DailyLogIn="Daily Log-in";
    CompleteYourDaily="Complete your daily tasks and claim your rewards!";
    Day="Day";
    EarnMoreCoins="Earn more coins";
    Follow="Follow";
    Subscribe="Subscribe";
    Join="Join";
    //
    HowToEarn="How to EARN";
    GetReadyForkWikzCoin="Get ready for Wikz Coin";
    WikZAppLaunched="WikZ App Launched";
    NOTWIKZReferralPool="NOT / WIKZ Referral Pool ";
    WikZCoinsBigAirdrop="WikZ Coins Big Airdrop";
    ExchangeListing="Exchange Listing";
    Soon="Soon";
    //
    Vibrate="Vibrate";
    Language="Language";
    Dashboard="Dashboard";
    Orders="Orders";
    Support="Support";
    TelegramChannel="Telegram Channel";
    //
    YourBalance="Your Balance";
    Loading="Loading";
    Spinning="Spinning";
    Spin="Spin";
}
export default LanguageKeysEN;
