import React, { useEffect } from 'react';
import { useAppSelector } from '../../store';
import { useAppDispatch } from '../../store';
import { removeProductFromBasket, updateProductQuantity } from '../../slices/shopBasket/slice';
import { motion } from 'framer-motion';
import { AiOutlineDelete } from "react-icons/ai";
import "./style.css";

interface DrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  onCheckout: () => void;
}

const BasketDrawerComp = (props: DrawerProps) => {
  const dispatch = useAppDispatch();
  const productBasket = useAppSelector((state) => state.basket);

  const handleRemoveProduct = (
    productId: string,
    color?: string,
    size?: string
  ) => {
    dispatch(removeProductFromBasket({ id: productId, color, size }));
  };

  useEffect(() => {
    const updateProductQuantities = async () => {
      for (const product of productBasket.products) {
        try {
          await dispatch(
            updateProductQuantity({ id: product.id, quantity: product.productQuantity })
          );
        } catch (error) {
          console.error('Error updating product', error);
        }
      }
    };

    updateProductQuantities();
  }, [productBasket.products, dispatch]);

  if (!props.isOpen) return null;

  return (
    <>
    <div className="drawer-header" style={{ borderBottom: '1px solid white', paddingBottom: '10px', paddingTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <span style={{
            fontSize: '1.2rem',
            fontWeight: '800',
            marginLeft: '15px'
          }}>My Cart</span>
        </div>
        <div className="drawer-content" style={{ marginTop: '10px', flex: '1', overflowY: 'auto' }}>
          {productBasket.products.length === 0 ? (
            <></>
          ) : (
            productBasket.products.map((product: any) => (
              <div
                key={product.id}
                className="drawer-item d-flex justify-content-between align-items-center"
                style={{
                  marginBottom: '10px',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                  paddingBottom: '10px',
                }}
              >
                <img
                  src={product.thumbnail_url}
                  alt={product.name}
                  style={{
                    width: '70px',
                    height: '105px',
                    marginRight: '10px',
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
                <div className="drawer-item-details" style={{ flex: '1' }}>
                  <p style={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'white',
                    padding: '0',
                    margin: '0',
                  }}>{product.name}</p>
                  <p style={{
                    fontSize: '0.8rem',
                    fontWeight: '200',
                    color: 'white',
                    padding: '0.5rem 0',
                    margin: '0',
                  }}>Quantity: {product.productQuantity}</p>
                  <p style={{
                    fontSize: '1rem',
                    fontWeight: '900',
                    color: 'white',
                    padding: '0',
                    margin: '0',
                  }}>${(product?.retail_price * product.productQuantity).toFixed(2)}</p>
                </div>

                <button
                  style={{
                    background: 'transparent',
                    borderRadius: '100%',
                    border: 'none',
                    color: 'white',
                    cursor: 'pointer',
                    fontSize: '18px',
                    fontWeight: '300',

                  }}
                  onClick={() => handleRemoveProduct(product.id.toString(), product.color, product.size)}
                >
                  <AiOutlineDelete />
                </button>
              </div>
            ))
          )}
        </div>
        <div className="drawer-footer" style={{ paddingTop: '10px' }}>
          <div className="d-flex justify-content-between">
            <p>Total: </p>
            <p>${productBasket.productTotalAmount}</p>
          </div>
          <div className="drawer-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              className="btn w-100 m-1"
              style={{ backgroundColor: '#1e89e4', color: 'white', fontSize: "0.8rem", fontWeight: '800', }}
              onClick={props.closeDrawer}
            >
              Close Drawer
            </button>
            <button
              className="btn w-100 m-1"
              style={{
                backgroundColor: productBasket.products.length === 0 ? 'grey' : '#5271ff',
                color: 'white',
                fontSize: "0.8rem",
                fontWeight: '800',
                border:"none",
                cursor: productBasket.products.length === 0 ? 'not-allowed' : 'pointer',
              }}
              onClick={() => {
                if (productBasket.products.length > 0) {
                  props.closeDrawer();
                  props.onCheckout();
                }
              }}
              disabled={productBasket.products.length === 0} // Disable when no products
            >
              Checkout
            </button>

          </div>
        </div>
    </>
  );
};

export default BasketDrawerComp;
