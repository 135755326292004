import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap";

//redux
import { start, stackClaim } from "../../../slices/api";
import { setIcon } from "../../../slices/selected-icon/slice";
import { cardsSelector } from "../../../slices/card/slice";
import { xpSelector } from "../../../slices/xp/slice";
import { AppDispatch, RootState } from "../../../store";

import {
  open,
  close,
  notificationSelector,
} from "../../../slices/notification/slice";

import { setMenu } from "../../../slices/selected-menu/slice";

//images
import CoinIcon from "../../../assets/icons/coin-icon.png";
import EarningLogo from "../../../assets/images/EarningLogo.png";
import remainTime from "../../../assets/images/remain-time.png";
import Rank from "../../../assets/images/1st.png";

//components
import Product from "./product";
import SlideUpPanel from "../../TaskSlider";

import formatNumber from "../../../helpers/format-number";
import { dailyCardSelector } from "../../../slices/daily-card/slice";
import { stackSelector } from "../../../slices/stack/slice";
import Spinner from "../../spinners/Spinner";

// constants
const CATEGORIES = [
  {
    name: "Electronics",
    slug: "electronics",
  },
  {
    name: "Fashion",
    slug: "fashion",
  },
  {
    name: "Vehicle",
    slug: "vehicle",
  },
  {
    name: "Real Estate",
    slug: "real-estate",
  },
];

// functions
const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
    remainingSeconds < 10 ? "0" : ""
  }${remainingSeconds}`;
};

export const Earn = () => {
  const xp = useSelector(xpSelector);

  const cards = useSelector(cardsSelector);
  const selectedIcon = useSelector((state: any) => state.selectedIcon.icon);
  const notification = useSelector(notificationSelector);
  const stack = useSelector(stackSelector);
  const { status: cardStatus } = useSelector(dailyCardSelector);

  const isXpLoading = useSelector(
    (state: RootState) => state["xp/api"].queries["xp({})"].status
  );
  const isCardsLoading = useSelector(
    (state: RootState) => state["card/api"].queries["cards({})"].status
  );


  //render when cards updated
  useEffect(() => {
    handleIconClick(selectedIcon);
  }, [isCardsLoading, isXpLoading]);

  const dispatch = useDispatch<AppDispatch>();
  const [selectedCategory, setSelectedCategory] = useState([] as any[]);

  const handleIconClick = (icon: string) => {
    setSelectedCategory(
      cards.filter((item: any) => {
        return item.info.category === icon;
      })
    );
    dispatch(setIcon(icon));
  };

  const { status, earnedXp } = useSelector(
    (state: RootState) => state["stack/app"]
  );

  useEffect(() => {
    if (!status.isWaiting) {
      document.body.style.backgroundColor = "#FF92FF";
    }
  }, [status.isWaiting]);

  const startSelling = async () => {
    dispatch(start.initiate({}));
  };

  const claimSelling = async () => {
    dispatch(stackClaim.initiate({}));
  };

  return (
    <section className="mine-section">
      <div className="d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center" style={{
          margin: "1.2rem 0 1.2rem 0",
        }}>
          <Image src={CoinIcon} alt="" className="earn-logo me-2" />
          <p className="earn-amount">{Math.round(xp.point).toLocaleString()}</p>
        </div>
      </div>

      <Spinner />
    

      {/* <SlideUpPanel show={notification} onClose={() => {}}>
        {
          <>
            <div className="d-flex align-items-center w-100">
              <h5
                className="my-3 flex-grow-1 text-center"
                style={{ marginRight: "-25px" }}
              >
                Lucky Item
              </h5>
              <span
                onClick={() => {
                  dispatch(close());
                }}
                className="close cursor-pointer d-flex align-items-center justify-content-end"
              >
                <i className="bi bi-x-circle"></i>
              </span>
            </div>
            <p className="w-75 m-0">
              Congratulations, you won 5,000,000 Coins by finding the product of
              the day.
            </p>
            <div className="lucky-Item notification my-3">
              <Image
                src={cardStatus.card.image}
                alt={`Lucky Item`}
                className="luckyItem-image"
              />
            </div>
            <div>
              <button
                className="claim-button mt-3"
                onClick={() => {
                  dispatch(close());
                  setTimeout(() => {
                    dispatch(setMenu("task"));
                  }, 300);
                }}
              >
                Claim
              </button>
            </div>
          </>
        }
      </SlideUpPanel> */}
    </section>
  );
};
