import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import getStore from "../../store/get-store";
import SpinButton from "../buttons/SpinButton";
import { Image } from "react-bootstrap";
import SortIcon from "../../assets/icons/sort-icon.png";
import KeyIcon from "../../assets/icons/key-icon.png"
import { setMenu } from "../../slices/selected-menu/slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { xp } from "../../slices/api";

import { useT } from "../../utils/locales/useLanguageState";

interface IPickerComp {
  items: string[];
  onIndexChange: () => void;
}

interface SpinReward {
  id: number;
  createdAt: string;
  updatedAt: string;
  publishedAt: string | null;
  type: string;
  threshold: number;
  amount: number;
  icon: {
    id: number;
    name: string;
    alternativeText: string | null;
    caption: string | null;
    width: number;
    height: number;
    formats: any;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string | null;
    provider: string;
    provider_metadata: any;
    folderPath: string;
    createdAt: string;
    updatedAt: string;
  } | null;
}

const PickerComp = (props: IPickerComp) => {
  const t = useT();

  const dispatch: AppDispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [spinTriggered, setSpinTriggered] = useState(false);
  const [scrollingToId, setScrollingToId] = useState<number | null>(null);
  const [spinItems, setSpinItems] = useState<SpinReward[]>([]);
  const [idToIndexMap, setIdToIndexMap] = useState<Record<number, number>>({});
  const maxScrollTopRef = useRef(0);

  const animationSpeed = 10; // Speed in pixels per frame
  const itemHeight = 40;
  const visibleItemsCount = 5;
  const containerHeight = itemHeight * visibleItemsCount;

  // Fetch and shuffle items
  useEffect(() => {

    const jwt = getStore().getState()["auth/app"].data.jwt;

    const fetchSpinRewards = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/spin-rewards`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        const rewards: SpinReward[] = response.data;

        const formattedItems = rewards.map((reward) => ({
          display: `${reward.amount} ${reward.type.toUpperCase()}`,
          iconUrl: reward.icon ? reward.icon.url : "", // Use empty string if no icon
        }));

        const idIndexMap = rewards.reduce((map, reward, index) => {
          map[reward.id] = index;
          return map;
        }, {} as Record<number, number>);

        setSpinItems(rewards);
        setIdToIndexMap(idIndexMap);
      } catch (error) {
        console.error("Error fetching spin rewards:", error);
      }
    };

    fetchSpinRewards();
  }, []);

  // Extend items list for seamless scrolling
  const extendedItems = [...spinItems, ...spinItems, ...spinItems]; // Duplicate items to create a seamless effect

  // Animate the picker infinitely if spin is triggered

  useEffect(() => {
    if (!isAnimating || !spinTriggered) return;
  
    const maxScrollTop = spinItems.length * itemHeight;
    let scrollTopRef = 0; // `useRef` yerine direkt lokal değişken olarak kullanacağız
    let animationFrameId: number | null = null;
  
    const animate = () => {
      if (containerRef.current) {
        scrollTopRef = (scrollTopRef + animationSpeed) % maxScrollTop;
        containerRef.current.scrollTop = scrollTopRef;
  
        // Animasyonu devam ettir
        animationFrameId = requestAnimationFrame(animate);
      }
    };
  
    // Animasyonu başlat
    animationFrameId = requestAnimationFrame(animate);
  
    return () => {
      if (animationFrameId !== null) {
        cancelAnimationFrame(animationFrameId);
      }
  
      // Animasyon bittiğinde spin'i durdur
      if (isAnimating && spinTriggered) {
        setSpinTriggered(false);
      }
    };
  }, [isAnimating, spinTriggered]);
  
  
  

  // Handle scrolling to selected index
  // Picker item'a kaydırma animasyonu
  useEffect(() => {
    console.log("Scrolling to ID:", scrollingToId);
    console.log("itemHeight", itemHeight);
    console.log("idToIndexMap", idToIndexMap);
    console.log("spinItems.length,", spinItems.length);
    console.log("containerHeight", containerHeight);

    if (scrollingToId !== null && containerRef.current) {
      const index = idToIndexMap[scrollingToId]; // Seçili item'ın indeksi

      // Hedef scroll pozisyonu hesaplanıyor
      const targetScrollTop = (index + spinItems.length) * itemHeight - (containerHeight / 2) + itemHeight / 2;

      let currentScrollTop = containerRef.current.scrollTop;
      let startTime: number | null = null;
      const duration = 2500; // Animasyon süresi

      const animateScroll = (time: number) => {
        if (startTime === null) startTime = time;
        const elapsed = time - startTime;
        const progress = Math.min(elapsed / duration, 1);

        // Easing fonksiyonu ile yavaşlama sağlanıyor
        const easing = 1 - Math.pow(1 - progress, 3);
        const newScrollTop = currentScrollTop + easing * (targetScrollTop - currentScrollTop);
        containerRef.current!.scrollTop = newScrollTop;

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        } else {
          props.onIndexChange();
          setIsAnimating(false);
          setSpinTriggered(false); // Spin hareketi tamamlandı
          setScrollTop(newScrollTop);
        }
      };

      requestAnimationFrame(animateScroll);
    }
  }, [scrollingToId]);



  const handleSpinClick = (rewardId: number) => {
    if (isAnimating) return; // Eğer animasyon zaten devam ediyorsa, başka bir spin başlatma
    setScrollingToId(null); // Kaydırma işlemi sıfırla
    setSelectedId(null); // Seçili item'ı sıfırla
    setIsAnimating(true); // Animasyon başlasın
    setSpinTriggered(true); // Spin hareketi başlatıldı

    // Animasyonun ne kadar süreceği (örneğin 5 saniye)
    const animationDuration = 3000;

    // Bu süre sonunda duracak şekilde zamanlayıcı başlatıyoruz
    setTimeout(() => {
      dispatch(xp.initiate({})).refetch().then((res: any) => {
        setSelectedId(rewardId);
        setScrollingToId(rewardId); // Seçili item'a kaydır
      }).catch((err: any) => {
        console.error("Error initiating XP:", err);
      });
    }, animationDuration);
  };


  return (
    <div className="picker">
      <div
        style={{
          display: "flex",
          margin: "0 0 0.5rem 0",
          height: "3rem",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5rem",
        }}
      >
        <div className="picker-title">
          <h3 style={{ fontWeight: 1000, margin: 0 }}>{t("SpinTheWheel")}</h3>
        </div>
        <div style={{
          gap: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Image
            src={SortIcon}
            alt={"Sort Icon"}
            style={{
              width: "24px",
              height: "24px",
              marginLeft: "-5px",
              marginRight: "-4px",
            }}
            onClick={() => dispatch(setMenu("name"))}
          />
          <div style={{
            gap: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5em",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <Image
                src={KeyIcon}
                alt={"Key Icon"}
                style={{
                  width: "24px",
                  height: "24px",
                  marginLeft: "-5px",
                  marginRight: "-4px",
                  borderRadius: "4px",
                }}
                onClick={() => { }}
              />
              <h1 style={{
                fontSize: "0.9rem",
                fontWeight: 800,
                margin: 0,
              }}>
                3
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="picker-wrapper">
        <div className="picker-container">
          <div
            ref={containerRef}
            className="picker-content"
            style={{ height: containerHeight, overflow: "hidden" }}
          >
            {extendedItems.map((item, index) => {
              // Başlangıç opacity değerleri
              let opacity = 1;
              // Animasyon başlamadan önce belirli item'ların opacity'sini değiştirme
              if (!isAnimating) {
                if (index === 0) opacity = 0.3;
                if (index === 1) opacity = 0.5;
                if (index === 3) opacity = 0.5;
                if (index === 4) opacity = 0.3;
              }

              return (
                <div
                  key={index}
                  className="picker-item"
                  style={{
                    fontSize: "23px",
                    color: item.id === selectedId ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.5)",
                    fontWeight: item.id === selectedId ? 800 : 300,
                    height: itemHeight,
                    opacity: isAnimating ? 1 : opacity, // Animasyon başladığında opacity'yi 1'e ayarlama
                    transform: `scale(${item.id === selectedId ? 1.2 : 1})`,
                  }}
                >
                  <img
                    src={item.icon ? item.icon.url : "default-icon-url.png"}
                    alt={item.type}
                    width={30}
                    height={30}
                    style={{
                      margin: "0 0.7rem",
                    }}
                  />
                  {`${item.amount} ${item.type.toUpperCase()}`}
                </div>
              )
            })}
          </div>
        </div>
        <div className="picker-actions">
          <SpinButton spin={(reward) => handleSpinClick(reward)} />
        </div>
      </div>
    </div>
  );
};

export default PickerComp;
