import React, { useEffect, useState } from "react";
import "./styles.css";

import { useFetchShopProductQuery } from "../../../slices/shopProduct/api";
import SkeletonLoader from "../../loadings/skeletonLoader";

interface IProductCard {
    productId: string
    onCartClick: (productId: string) => void;
}

const StoreProductCard = (props: IProductCard) => {

    const { data, refetch, isLoading } = useFetchShopProductQuery({ id: props.productId }, { skip: false });

    useEffect(() => {
        refetch();
    }, [props.productId, isLoading]);

    return (
        isLoading ? <SkeletonLoader /> :
            <div onClick={() => props.onCartClick(props.productId)}
                className="col-6 col-md-4 col-lg-3 mb-3" style={{
                    padding: '0 5px',
                }}>
                <div className="card" style={{ backgroundColor: '#313f54', borderRadius: '10px', height: "180px", marginBottom: "80px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', border:'none' }}>
                    <div className="card-body text-center p-0" style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={
                                //@ts-ignore
                                data?.sync_product.thumbnail_url
                            }
                            alt={
                                //@ts-ignore
                                data?.sync_product.name
                            }
                            className="img-fluid"
                            style={{
                                borderRadius: '10px',
                                maxWidth: '175px',
                                maxHeight: '100%',
                                objectFit: 'cover' // Ensures the image fits within the card without distortion
                            }}
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        marginLeft: '10px',
                        marginTop:"10px",
                    }}>
                        <div className="card-header" style={{ 
                            color: '#ffffff', 
                            borderBottom: 'none', 
                            padding: 0, 
                            maxHeight: '2.5rem',
                            textAlign: 'left',
                            lineHeight: '18px',
                            height: '2.5rem',
                        }}>
                            <span style={{
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            }}>
                                {
                                    //@ts-ignore
                                    data?.sync_product.name
                                }
                            </span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                            <span style={{ color: '#ffffff', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontFamily: "Impact" }}>
                                <strong style={{ fontSize: "1.4rem", fontWeight: '700' }}>
                                    <span style={{ marginRight: "0.3rem" }}>$</span>
                                    <span>
                                        {
                                            //@ts-ignore
                                            data?.sync_variants[0].retail_price.toString().split('.').map((part, index) => (
                                                <span key={index} style={{ textDecoration: 'none' }}>
                                                    {index === 0 ? (
                                                        <span style={{ textDecoration: 'underline' }}>{part}</span>
                                                    ) : (
                                                        <span style={{ fontSize: '1rem', marginBottom: '2rem' }}>
                                                            <sup>.{part}</sup>
                                                        </span>
                                                    )}
                                                </span>
                                            ))}
                                    </span>
                                </strong>
                            </span>
                        </div>
                    </div>
                </div>

            </div>
    );
}

export default StoreProductCard;