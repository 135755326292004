import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { updateProductQuantity, removeProductFromBasket } from '../../slices/shopBasket/slice';
import axios from 'axios';

import { AiOutlineDelete } from "react-icons/ai";

import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { clearBasket } from '../../slices/shopBasket/slice';
import { setPaymentSuccess } from '../../slices/payment/slice';
import { setMenu } from '../../slices/selected-menu/slice';
//Components
import OrderQuantity from '../../helpers/product/orderQuantity';
import CountrySelector from '../../components/selectors/CountrySelector';
import StateSelector from '../../components/selectors/StateSelector';
import CitySelector from '../../components/selectors/CitySelector';
import OrderLoader from '../../components/loadings/orderLoader';
//Interfaces
import { IOrderFormInput } from '../../types/interfaces/forms/IForm';
import { IProductModel } from '../../types/models/redux/productModel';

import "./style.css";


interface IProductPaymentPage {
    onPayNow: () => void;
}

const BasketProductsPaymentPage = (props: IProductPaymentPage) => {
    const dispatch = useAppDispatch();
    const productBasket = useAppSelector((state) => state.basket);


    const methods = useForm<IOrderFormInput>({
        mode: "onChange", // Değişiklik anında validasyon yapar
    });
        
    const { register, handleSubmit, formState: { errors }, watch} = methods;

    const [orderStatusLoading, setOrderStatusLoading] = useState(false);
    const [orderStatus, setOrderStatus] = useState('');

    const [isPayNowDisabled, setIsPayNowDisabled] = useState(true); // State for button disable

    
    const formValues = watch();

    const onSubmit: SubmitHandler<IOrderFormInput> = async (data) => {
        const { name, address1, city, state_code, country_code, zip, email, phone } = data;
        const recipient = { name, address1, city, state_code, country_code, zip };
        const packingSlip = { email, phone, message: 'Custom packing slip', logo_url: 'https://i.picsum.photos/id/817/2000/2000.jpg' };

        const items = productBasket.products.map((product: any) => ({
            variant_id: product.variant_id,
            quantity: product.productQuantity,
            files: [{ type: product.files[0].type, url: product.files[0].thumbnail_url }],
            options: {
                stitch_color: product.color
            }
        }));

       

        try {
            setOrderStatusLoading(true);

            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/store/orders`, {
                recipient,
                items,
                packing_slip: packingSlip
            });

            if (response.status === 201) {
                dispatch(setPaymentSuccess({
                    product: productBasket,
                    payment: {
                        recipient,
                        totalAmount: productBasket.retail_price * productBasket.productQuantity
                    }
                }));
                dispatch(setMenu("productInvoice"));
                setOrderStatus('201');
                dispatch(clearBasket());
            }
        } catch (error) {
            setOrderStatus('400');
        }

    };


    useEffect(() => {
        const allFieldsFilled = Object.values(formValues).every(value => value);
        const hasErrors = Object.keys(errors).length > 0;

        setIsPayNowDisabled(!(allFieldsFilled && !hasErrors));
    }, [formValues, errors]);

    // Calculate total amount directly in the component
    const calculateTotalAmount = () => {
        return productBasket.products.reduce((total: any, product: any) => {
            return total + product?.retail_price * product.productQuantity;
        }, 0);
    };

    const handleQuantityChange = (productId: string, newQuantity: number, color?: string, size?: string) => {
        dispatch(updateProductQuantity({ id: productId, color, size, quantity: newQuantity }));
    };

    const handleRemoveProduct = (productId: string, color?: string, size?: string) => {
        dispatch(removeProductFromBasket({ id: productId, color, size }));
    };


    return (
        <div className="mb-5 mt-3">
            <OrderLoader
                loading={orderStatusLoading}
                status={orderStatus}
                onFinish={() => window.location.reload()} />
            <h4 className='mb-3'>Your Order</h4>
            {productBasket.products.length === 0 ? (
                <p>Your cart is currently empty.</p>
            ) : (
                productBasket.products.map((product: IProductModel) => (
                    <div
                        className="card mb-3 position-relative"
                        style={{
                            minHeight: '85px',
                            maxHeight: '110px',
                            backgroundColor: '#313f54',
                            color: '#fff',
                            overflow: 'hidden',
                            borderRadius: '15px',
                            padding: '0 0 5px 0',
                            border: "none"
                        }}
                        key={product.id}
                    >
                        <div className="row no-gutters align-items-center" style={{
                            padding: '0 20px 0 0',
                        }}>
                            <div className="col-4 d-flex align-items-center justify-content-center m-0">
                                <img
                                    src={product.thumbnail_url}
                                    alt={product.name}
                                    className="img-fluid"
                                    style={{
                                        width: '70px',
                                        height: '70px',
                                        marginRight: '10px',
                                        objectFit: 'contain',
                                        objectPosition: 'center',
                                    }}
                                />
                            </div>
                            <div className="col-5 d-flex flex-column justify-content-center align-items-start p-0">
                                <span style={{
                                    fontSize: '1rem',
                                    fontWeight: '300',
                                    color: 'white',
                                    padding: '0',
                                    margin: '0 0 1rem 0',
                                }}>{product.name}</span>
                                <div style={{
                                    alignItems: 'center',
                                    width: '100px',
                                }}>
                                    <OrderQuantity
                                        onQuantityChange={(newQuantity) => handleQuantityChange(product.id.toString(), newQuantity, product.color, product.size)}
                                        initialQuantity={product.productQuantity}
                                    />
                                </div>
                            </div>
                            <div className="col-3 d-flex flex-column justify-content-end align-items-center" style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                height: '80px',
                                fontSize: '1rem',
                                fontWeight: '900',
                            }}>
                                <span className="font-weight-bold">${(product?.retail_price * product.productQuantity).toFixed(2)}</span>
                            </div>
                            {/* X Button in the top-right corner */}
                            <button
                                onClick={() => handleRemoveProduct(product.id.toString(), product.color, product.size)}
                                className="btn position-absolute"
                                style={{
                                    top: '10px',  // Top corner positioning
                                    right: '10px', // Right corner positioning
                                    width: '25px', // Set width
                                    height: '25px', // Set height
                                    padding: '0', // Remove padding for perfect square
                                    opacity: 0.7, // Semi-transparent
                                    display: 'flex', // Flexbox for centering the text
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '18px', // Adjust font size for small button
                                    lineHeight: '1' // Ensure text fits well
                                }}
                            >
                                <AiOutlineDelete />
                            </button>
                        </div>
                    </div>

                ))
            )}

            <div className="card mb-2" style={{ backgroundColor: 'transparent', color: '#fff', border:"none" }}>
                <div className="card-body p-0">
                    <h4 className='mb-3'>Address</h4>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            {/* Name field */}
                            <div className="form-group mb-3">
                                <input
                                    {...register("name", {
                                        required: "The name is required.",
                                        minLength: {
                                            value: 5,
                                            message: "The name must be at least 5 characters."
                                        },
                                        pattern: {
                                            value: /^[A-Za-zğüşİĞÜŞÇçÖö\s]+$/,
                                            message: "The name must consist of letters and spaces only."
                                        }                                        
                                    })}
                                    type="text"
                                    className={`form-control ${errors.name ? 'is-invalid' : formValues.name ? 'is-valid' : ''}`}
                                    id="exampleInputName"
                                    placeholder="Full Name"
                                />
                                {errors.name ? (
                                    <div className="invalid-feedback">
                                        {errors.name.message}
                                    </div>
                                ) : formValues.name ? (
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                ) : null}
                            </div>

                            {/* Email field */}
                            <div className="form-group mb-3">
                                <input
                                    {...register("email", {
                                        required: "Email is required.",
                                        minLength: {
                                            value: 10,
                                            message: "Email must be at least 10 characters."
                                        },
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message: "Enter a valid email format."
                                        }
                                    })}
                                    type="text"
                                    className={`form-control ${errors.email ? 'is-invalid' : formValues.email ? 'is-valid' : ''}`}
                                    id="exampleInputEmail1"
                                    placeholder="Email"
                                />
                                {errors.email ? (
                                    <div className="invalid-feedback">
                                        {errors.email.message}
                                    </div>
                                ) : formValues.email ? (
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                ) : null}
                            </div>


                            {/* Phone field */}
                            <div className="form-group mb-3">
                                <input
                                    {...register("phone", {
                                        required: "Phone number is required.",
                                        minLength: {
                                            value: 10,
                                            message: "The phone number must be at least 10 characters."
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "The phone number must consist of numbers only."
                                        }
                                    })}
                                    type="text"
                                    className={`form-control ${errors.phone ? 'is-invalid' : formValues.phone ? 'is-valid' : ''}`}
                                    id="exampleInputPhone1"
                                    placeholder="Phone"
                                />
                                {errors.phone ? (
                                    <div className="invalid-feedback">
                                        {errors.phone.message}
                                    </div>
                                ) : formValues.phone ? (
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                ) : null}
                            </div>

                            <CountrySelector />
                            <StateSelector />
                            <CitySelector />
                            {/* Address field */}
                            <div className="form-group mb-3">
                                <input
                                    {...register("address1", {
                                        required: "Address is required.",
                                        minLength: {
                                            value: 10,
                                            message: "The address must be at least 10 characters."
                                        }
                                    })}
                                    type="text"
                                    className={`form-control ${errors.address1 ? 'is-invalid' : formValues.address1 ? 'is-valid' : ''}`}
                                    id="exampleInputAddress1"
                                    placeholder="Address"
                                />
                                {errors.address1 ? (
                                    <div className="invalid-feedback">
                                        {errors.address1.message}
                                    </div>
                                ) : formValues.address1 ? (
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                ) : null}
                            </div>


                            {/* Zip field */}
                            <div className="form-group mb-3">
                                <input
                                    {...register("zip", {
                                        required: "Zip code is required.",
                                        minLength: {
                                            value: 2,
                                            message: "The postal code must be at least 2 characters."
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "The postal code must be no more than 10 characters."
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "The postal code must consist of numbers only."
                                        }
                                    })}
                                    type="text"
                                    className={`form-control ${errors.zip ? 'is-invalid' : formValues.zip ? 'is-valid' : ''}`}
                                    id="exampleInputZip"
                                    placeholder="Zip"
                                />
                                {errors.zip ? (
                                    <div className="invalid-feedback">
                                        {errors.zip.message}
                                    </div>
                                ) : formValues.zip ? (
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                ) : null}
                            </div>
                            <div>
                                <h4 className='mb-3 mt-5'>Payment Information</h4>
                                <div className="card-body p-0">
                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <span style={{ color: "#b4b2b9" }}>Order Amount:</span>
                                        </div>
                                        <div className="col-6 text-right d-flex" style={{
                                            color: '#b4b2b9',
                                            justifyContent: 'flex-end',
                                        }}>
                                            <span style={{ color: '#b4b2b9' }}>{calculateTotalAmount().toFixed(2)} USD</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <span style={{ color: "#b4b2b9" }}>Shipping:</span>
                                        </div>
                                        <div className="col-6 text-right d-flex" style={{
                                            color: '#b4b2b9',
                                            justifyContent: 'flex-end',
                                        }}>
                                            <span style={{ color: '#b4b2b9' }}>{calculateTotalAmount().toFixed(2)} USD</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <span style={{ fontWeight: 800 }}>Total:</span>
                                        </div>
                                        <div className="col-6 text-right d-flex" style={{
                                            justifyContent: 'flex-end',
                                        }}>
                                            <span style={{ fontWeight: 800 }}>{calculateTotalAmount().toFixed(2)} USD</span>
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <select
                                            className="form-control"
                                            style={{
                                                backgroundColor: 'transparent',
                                                color: '#fff',
                                                border: '1px solid #5271ff',
                                                fontSize: '1rem',
                                                fontWeight: '300',
                                            }}
                                        >
                                            <option>TON</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="d-flex text-left m-8 justify-content-end">
                                    <button
                                        style={{
                                            width: '100px',
                                            backgroundColor: `${isPayNowDisabled ? 'gray' : '#33b83b'}`,
                                            color: '#fff',
                                            fontSize: '1rem',
                                            fontWeight: '800',
                                            marginTop: '10px',
                                            border:"none"
                                        }}
                                        type="submit"
                                        className={`btn btn-primary ${isPayNowDisabled ? 'disabled' : ''}`}
                                        disabled={isPayNowDisabled}
                                    >Pay Now</button>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    );
};

export default BasketProductsPaymentPage;
