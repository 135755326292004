import React, { useState } from "react";
import notIcon from "../../assets/icons/not-icon.png";
import notCircleIcon from "../../assets/logos/not-circle-dark-logo.png";
import giftIcon from "../../assets/icons/gift-icon.png"
import wikzIcon from "../../assets/logos/wikz-circle-dark-logo.png"
import coin from "../../assets/images/coin.webp";
import { Image } from "react-bootstrap";
import WebApp from "@twa-dev/sdk";
import { frensSelector } from "../../slices/frens/slice";
import { useSelector } from "react-redux";
import { useT } from "../../utils/locales/useLanguageState";

export const Friends = () => {

  const t = useT();
  const [copied, setCopied] = useState(false);
  const { referrers } = useSelector(frensSelector);
  const link = `https://t.me/wikz_test_bot/start?startapp=${WebApp.initDataUnsafe?.user?.id}`; // Replace with your actual link

  const handleCopy = () => {
    navigator.clipboard
      .writeText(
        `${link}`
      )
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
  };

  const shareBotLink = () => {
    // Telegram kullanıcılarına bot linkini mesaj olarak paylaşın
    //@ts-ignore
    window.Telegram.WebApp.openLink(`tg://msg?text=Check out our bot: ${link}`);
  };

  return (
    <section className="frens-section">
      <p className="heading my-1">{t("InviteFriends")}</p>
      <p className="heading mt-1 mb-3">{t("GiveAwayWithoutLottery")}</p>
      <div className="prize-pool m-1" style={{
        padding:"1rem 0 0.5rem 1rem"
      }}>
        <div className="rewards">
          <div className="title">{t("ReferralPrizePool")}</div>
          <div className="ton">1,000,000 NOT</div>
          <div className="shpc">200,000,000 WIKZ</div>
        </div>
        <div className="bigger-ton m-1" style={{
          justifyContent:"center",
          display:"flex",
          alignItems:"center",
        }}>
          <Image className="p-1" src={notIcon}></Image>
        </div>
      </div>

      <div className="invite-information m-3">
        <Image className="m-2" src={giftIcon}></Image>
        <div className="rewards">
          <div className="title">{t("InviteAFriend")}</div>
          <div className="tokens">
            <div className="reward">
              <div className="smaller-coin m-1">
                <Image className="p-1" src={coin}></Image>
              </div>
              <div className="coin">
                <div>100</div>
                <div className="info">{t("ForYouAndYourFriend")}</div>
              </div>
            </div>
          </div>
          <div className="tokens">
            <div className="reward">
              <div className="smaller-coin m-1">
                <Image style={{
                  padding: "3px",
                }} src={notCircleIcon}></Image>
              </div>
              <div className="ton">0.5 NOT</div>
            </div>
            <div className="reward">
              <div className="smaller-coin m-1">
                <Image className="p-1" src={wikzIcon}></Image>
              </div>
              <div className="ton">20 WIKZ</div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        width:"100%",
        justifyContent:"space-around",
        }}>
        <div className="claim-text mt-3" onClick={shareBotLink}>
          {copied ? t("Copied") : t("InviteAFriend")}
        </div>
        <button className="coppy-button mt-3" onClick={handleCopy}>
          <i className="bi bi-copy mx-2"></i>
        </button>
      </div>
      <div className="friend-list-container">
        <p className="heading mb-1 mt-3">
         {t("ListOYourFriends")}({referrers.length})
        </p>
        {referrers.map((refer: any, idx: number) => (
          <div key={idx} className="friend-list my-1">
            <h4 className="refer-title m-0">
              <div className="count">{idx + 1}</div>
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.3rem",
              }}>
                {refer}
                <div className="d-flex align-items-center">
                  <img
                    src={coin}
                    alt=""
                    className="menu-logo me-2"
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                  <p className="refer-number">
                    {Number(100).toLocaleString()}
                  </p>
                </div>
              </div>
            </h4>
            <div className="rewards mx-3">
            <div className="reward">
                <div className="smaller-coin">
                  <Image className="p-1" src={wikzIcon}></Image>
                </div>
                <div className="ton">20 WIKZ</div>
              </div>
              <div className="reward">
                <div className="smaller-coin">
                  <Image className="p-1" src={notCircleIcon}></Image>
                </div>
                <div className="ton">0.5 NOT</div>
              </div>
              
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
