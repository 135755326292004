import { combineReducers } from "@reduxjs/toolkit";
import selectedIconReducer from "./selected-icon/slice";
import selectedMenuReducer from "./selected-menu/slice";
import authReducer from "./auth/slice";
import authApi from "./auth/api";
import cardReducer from "./card/slice";
import cardApi from "./card/api";
import xpReducer from "./xp/slice";
import xpApi from "./xp/api";
import stackReducer from "./stack/slice";
import stackApi from "./stack/api";
import taskReducer from "./task/slice";
import taskApi from "./task/api";
import dailyReducer from "./daily-login/slice";
import dailyApi from "./daily-login/api";
import frensReducer from "./frens/slice";
import frensApi from "./frens/api";
import dailyCardReducer from "./daily-card/slice";
import dailyCardApi from "./daily-card/api";
import dailyQuestionApi from "./daily-question/api";
import dailyQuestionReducer from "./daily-question/slice";
import leaderBoardApi from "./leader-board/api";
import notificationReducer from "./notification/slice";
import paymentReducer from "./payment/slice";

import usdtReducer from "./usdt/slice";
import usdtApi from "./usdt/api";

import notReducer from "./not/slice";
import notApi from "./not/api";

import wikzReducer from "./wikz/slice";
import wikzApi from "./wikz/api";


import shopProductReducer from "./shopProduct/slice";
import shopProductApi from "./shopProduct/api";
import shopBasketSlice from "./shopBasket/slice";
import shopViewSlice from "./shopViewer/slice"; 
import shopPurchaseSlice from "./shopPurchase/slice";

import countriesApi from "./countries/api";
import productsVariantApi from "./productsVariant/api";

const rootReducer = combineReducers({
  selectedIcon: selectedIconReducer,
  selectedMenu: selectedMenuReducer,

  basket:shopBasketSlice.reducer,
  shopViewer: shopViewSlice.reducer, // Ensure this is properly set
  shopPurchase: shopPurchaseSlice.reducer, // Ensure this is properly set

  "auth/app": authReducer,
  "auth/api": authApi.reducer,
  "card/app": cardReducer,
  "card/api": cardApi.reducer,
  "xp/app": xpReducer,
  "xp/api": xpApi.reducer,
  
  "usdt/app": usdtReducer,
  "usdt/api": usdtApi.reducer,

  "not/app": notReducer,
  "not/api": notApi.reducer,

  "wikz/app": wikzReducer,
  "wikz/api": wikzApi.reducer,

  "shopProduct/app": shopProductReducer,
  "shopProduct/api": shopProductApi.reducer,

  "stack/app": stackReducer,
  "stack/api": stackApi.reducer,
  "task/app": taskReducer,
  "task/api": taskApi.reducer,
  "daily-login/app": dailyReducer,
  "daily-login/api": dailyApi.reducer,
  "daily-card/app": dailyCardReducer,
  "daily-card/api": dailyCardApi.reducer,
  "frens/app": frensReducer,
  "frens/api": frensApi.reducer,
  "daily-question/app": dailyQuestionReducer,
  "daily-question/api": dailyQuestionApi.reducer,
  "leader-board/api": leaderBoardApi.reducer,
  "notification/app": notificationReducer,

  "payment/app": paymentReducer,

  "productsVariant/app": productsVariantApi.reducer,

  [countriesApi.reducerPath]: countriesApi.reducer,
});

export default rootReducer;
