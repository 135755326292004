import React from 'react';
import './styles.css';
import ApprovalIcon from '../../assets/icons/approval-icon.png';
import { GoClock } from "react-icons/go";
import { useT } from '../../utils/locales/useLanguageState';

import WikzLogo from '../../assets/logos/wikz-circle-light-logo.png';

const WikzSectionComp: React.FC = () => {
    const t = useT();

    return (
        <div>
            <div
                className="center-text"
                style={{
                    fontSize: "1.3rem",
                    fontWeight: "1000",
                    marginBottom: "1rem"
                }}
            >{t("GetReadyForkWikzCoin")}</div>
            <div className="wikz-section-comp">
                <div className='wikz-logo'>
                    <img
                        src={WikzLogo}
                        alt=""
                        className="logo"
                        style={{
                            width: "80px",
                            marginRight: "1.2rem",
                        }}
                    />
                </div>
                <ul className="wikz-info-list">
                    <li className="active">
                        <span className="icon">
                            <img
                                src={ApprovalIcon}
                                alt=""
                                className="logo"
                            />
                        </span>
                        {t("WikZAppLaunched")}
                    </li>
                    <li className="active">
                        <span className="icon">
                            <img
                                src={ApprovalIcon}
                                alt=""
                                className="logo"
                            />
                        </span>
                        {t("NOTWIKZReferralPool")}
                    </li>
                    <li className="disabled"><span className="icon"><GoClock fontSize={20} /></span>{t("WikZCoinsBigAirdrop")}</li>
                    <li className="disabled"><span className="icon"><GoClock fontSize={20}/></span>{t("ExchangeListing")}</li>
                </ul>
            </div>
        </div>

    );
};

export default WikzSectionComp;
