import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import getStore from '../../store/get-store';
import { useT } from '../../utils/locales/useLanguageState';
import { getStorageData } from '../../utils/localState/usePersistedState';

interface ISpinButton {
  spin: (reward: number) => void;
}

const SpinButton = (props: ISpinButton) => {
  const t = useT();
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);  // Interval referansı
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [canSpin, setCanSpin] = useState<boolean>(false);

  // Component ilk yüklendiğinde API'den kalan süreyi al
  useEffect(() => {
    fetchSpinStatus();

    // Component unmount olduğunda interval'i temizle
    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, []);

  // fetchSpinStatus fonksiyonu: spin status'u alır
  const fetchSpinStatus = async () => {
    try {
      const jwt = getStore().getState()["auth/app"].data.jwt;

      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/spin/status`, {
        headers: {
          'Authorization': `Bearer ${jwt}`,
        },
      });

      const data = response.data.data;
      setCanSpin(data.canSpin);
      if (!data.canSpin) {
        const remainingTime = data.remainTimeForSpin;

        // Geriye kalan süreyi localStorage'a kaydet
        localStorage.setItem('remainingTime', String(Date.now() + remainingTime * 1000));

        // Geri sayımı başlat
        startCountdown(remainingTime);
      }
    } catch (err: any) {
      if (err.response) {
        setError(err.response.data.message || 'An error occurred while fetching spin status.');
      } else if (err.request) {
        setError('No response received from server.');
      } else {
        setError('An error occurred while setting up the request.');
      }
    }
  };

  // Spin işleminden sonra geri sayım başlat
  const handleSpin = async () => {
    setLoading(true);
    setMessage(t("Spinning"));
    setError(null);

    try {
      const jwt = getStore().getState()["auth/app"].data.jwt;

      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/spin`, {}, {
        headers: {
          'Authorization': `Bearer ${jwt}`,
        },
      });

      const remainingTime = localStorage.getItem('remainingTime'); // changed variable name for clarity
      const timeLeft = remainingTime ? (Number(remainingTime) - Date.now()) / 1000 : 1; // Calculate remaining time in seconds
      startCountdown(Math.max(0, timeLeft)); // Pass the converted timeLeft to startCountdown, ensuring it's non-negative

      setCanSpin(false);
      props.spin(response.data.data.reward.id);

      // Spin işleminden sonra geri sayım başlat
    } catch (err: any) {
      if (err.response) {
        setError(err.response.data.message || 'An error occurred while spinning.');
      } else if (err.request) {
        setError('No response received from server.');
      } else {
        setError('An error occurred while setting up the request.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Geri sayımı başlatan fonksiyon
  const startCountdown = (seconds: number) => {
    // Eğer daha önce bir interval varsa temizle
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }

    let timeLeft = seconds + 1;

    countdownIntervalRef.current = setInterval(() => {
      timeLeft -= 1;
      setMessage(`${t("PleaseWait")} ${timeLeft} ${"Seconds"}`);

      // Eğer zaman biterse interval'i durdur ve spin status'ü kontrol et
      if (timeLeft <= 0) {
        clearInterval(countdownIntervalRef.current!);
        fetchSpinStatus(); // Geri sayım bittiğinde spin status kontrol edilir
      }
    }, 1000);
  };

  // Component her render edildiğinde localStorage'daki zamanı kontrol et ve geri sayımı devam ettir
  useEffect(() => {
    const savedTime = localStorage.getItem('remainingTime');

    if (savedTime) {
      const remainingTime = Math.max(0, Math.floor((Number(savedTime) - Date.now()) / 1000));

      if (remainingTime > 0) {
        startCountdown(remainingTime);
      } else {
        fetchSpinStatus(); // Kalan süre bittiyse tekrar API'den status al
      }
    }
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <button className='spin-button' onClick={handleSpin} disabled={loading || !canSpin}>
        {loading ? `${t("Spinning")}...` : !canSpin ? `${message}` : `${t("Spin")}`}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default SpinButton;
